<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.owner_branch") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                v-if="Object.keys(model_header).length && created_branch"
                size="small"
                class="p-2"
                type="primary"
                @click="drawer.update.status = true"
                icon="el-icon-edit"
                circle
              ></el-button>
              <el-button
                v-if="!Object.keys(model_header).length && created_branch"
                size="small"
                class="p-2"
                type="primary"
                @click="drawerCreate = true"
                icon="el-icon-plus"
                circle
              ></el-button>
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-form ref="form" :model="form" :rules="rules" class="aticler_m">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item :label="columns.name.title" prop="name">
            <crm-input v-model="form.name" autocomplete="off"></crm-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <span class="el-form-item__label">{{ $t("message.logo") }}</span>
          <div class="imgUpload">
            <el-upload
              :action="base_url + 'owner-branch/store-image'"
              :limit="1"
              accept="image/*"
              name="image"
              list-type="picture-card"
              :on-preview="handlePictureCardPreviewFull"
              :on-remove="handleRemove"
              :on-success="handleAvatarSuccessFull"
              :headers="{
                Authorization: 'Bearer ' + $store.getters.token,
              }"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisibleFull">
              <img width="100%" :src="dialogFullImageUrl" alt />
            </el-dialog>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="m-1">
        <el-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9">
          <el-input
            :placeholder="$t(`message.search`)"
            ref="search1"
            class="my-kh-search"
            size="small"
            v-model="search1"
          ></el-input>
          <el-tree
            class="filter-tree"
            :data="list"
            show-checkbox
            node-key="id"
            @check-change="selectedItem"
            :filter-node-method="filterNode"
            ref="items"
            :expand-on-click-node="true"
          >
            <span
              style="line-height: 150px"
              class="custom-tree-node"
              slot-scope="{ node, data }"
            >
              <span class="aaa my-tree-khan m-05">{{ data.name }}</span>
            </span>
          </el-tree>
        </el-col>
        <el-col :xs="24" :sm="24" :md="15" :lg="15" :xl="15">
          <el-input
            :placeholder="$t(`message.search`)"
            ref="search2"
            class="my-kh-search"
            size="small"
            v-model="search2"
          ></el-input>
          <div class="block">
            <el-tree
              class="filter-tree"
              :data="service"
              show-checkbox
              node-key="id"
              @check-change="selectedService"
              :filter-node-method="filterNode"
              ref="services"
              :expand-on-click-node="true"
            >
              <span
                style="line-height: 150px"
                class="custom-tree-node"
                slot-scope="{ node, data }"
              >
                <span class="aaa my-tree-khan m-05">
                  <div class="w-200">{{ data.name }}</div>
                </span>
              </span>
            </el-tree>
          </div>
        </el-col></el-row
      >
    </el-form>
    <el-drawer
      title="Новый тег"
      :visible.sync="drawerCreate"
      size="60%"
      :append-to-body="true"
      :wrapperClosable="false"
      :drawer="drawerCreate"
      :ref="drawer.create.name"
      @close="reloadIfChanged"
    >
      <div v-if="created_branch">
        <crm-create
          @c-close="closeDrawer"
          :drawer-name="drawer.create.name"
          :id="form.id"
        ></crm-create>
      </div>
    </el-drawer>
    <el-drawer
      size="60%"
      :wrapperClosable="false"
      :visible.sync="drawer.update.status"
      :ref="drawer.update.name"
      @opened="drawerOpened(drawer.update.component)"
      :append-to-body="true"
      @closed="drawerClosed(drawer.update.component)"
    >
      <crm-update
        :id="form.id"
        :ref="drawer.update.component"
        :drawer-name="drawer.update.name"
      >
      </crm-update>
    </el-drawer>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import form from "@/utils/mixins/form";
import drawer from "@/utils/mixins/drawer";
import drawerChild from "@/utils/mixins/drawer-child";
import CrmCreate from "./mini-header/crm-create";
import CrmUpdate from "./mini-header/crm-update";

export default {
  mixins: [form, drawerChild, drawer],
  components: {
    CrmCreate,
    CrmUpdate,
  },
  data() {
    return {
      data: [],
      services: [],
      fileList: [],
      dialogImageUrl: "",
      dialogFullImageUrl: "",
      dialogVisible: false,
      dialogVisibleFull: false,
      drawerPatientService: false,
      drawerMiniPrintCreate: false,
      drawerMiniPrintUpdate: false,
      search1: "",
      search2: "",
      drawerCreate: false,
      created_branch: null,
      selectedServices: [],
      selectedList: [],
      owner_clinic_image_id: null,
      defaultProps: {
        children: "children",
        label: "label",
        price: "price",
      },
      drawer: {
        create: {
          name: "create",
          status: false,
          component: "componentDrawerCreate",
        },
        update: {
          name: "update",
          status: false,
          component: "componentDrawerUpdate",
        },
      },
    };
  },
  created() {
    this.getUsers();
  },
  computed: {
    ...mapGetters({
      model_header: "ownerBranchHeaders/model",
      rules: "ownerBranches/rules",
      model: "ownerBranches/model",
      columns: "ownerBranches/columns",
      service: "services/relation_free_list",
      list: "users/free_list",
    }),
  },
  watch: {
    search1(val) {
      this.$refs.items.filter(val);
    },
    search2(val) {
      this.$refs.services.filter(val);
    },
  },
  methods: {
    ...mapActions({
      save: "ownerBranches/store",
      getUsers: "users/free_owner_branch",
      servicesRealtionList: "services/relationFreeList",
      destroy: "ownerBranches/destroyImage",
    }),
    handlePictureCardPreviewFull(file) {
      this.dialogFullImageUrl = file.url;
      this.dialogVisibleFull = true;
    },
    handleRemove(file, fileList) {
      this.destroy(file.response.data.image.id).then((res) => {});
    },
    handleAvatarSuccessFull(res, file) {
      this.form.owner_branch_image_id = res.data.image.id;
    },
    reloadIfChanged(modal) {
      this[modal] = false;
    },
    closeDrawer(drawer) {
      this.drawer.update.status = false;
      this.drawerCreate = false;
      this[drawer.drawer] = false;
      if (this[drawer.emptyModel]) {
        this.emptyModel();
      }
    },
    emptyModel() {},
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.form.doctors = JSON.parse(JSON.stringify(this.selectedList));
          this.form.services = JSON.parse(
            JSON.stringify(this.selectedServices)
          );
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$notify({
                title: "Успешно",
                type: "success",
                offset: 130,
                message: res.message,
              });
              this.created_branch = res.data;
              // this.parent().listChanged();
              // this.resetForm("form");
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$notify({
                title: "Ошибка",
                type: "error",
                offset: 130,
                message: err.error.message,
              });
            });
        }
      });
    },
    selectedService() {
      this.selectedServices = [];
      let half_item = this.$refs.services.getHalfCheckedNodes();
      let item = this.$refs.services.getCheckedNodes();
      for (const i in item) {
        if (item[i]) {
          const element = item[i];
          this.selectedServices.push(element);
        }
      }
      for (const i in half_item) {
        if (half_item[i]) {
          const element = half_item[i];
          this.selectedServices.push(element);
        }
      }
    },
    selectedItem() {
      this.selectedList = [];
      let item = this.$refs.items.getCheckedNodes();
      for (const i in item) {
        if (item[i]) {
          const element = item[i];
          this.selectedList.push(element);
        }
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      let dd = data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      return dd;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit("c-close", { drawer: "drawerCreate" });
    },
    onSelectType(data, node) {
      let parent = null;
      if (data && !data.parent_id) {
        parent = this.data.find((el) => el.id == data.id);
        parent.children.map((child) => {
          child.type = data.type;
        });
      }
    },
    onSelectAmount(data) {
      let parent = null;
      if (data && !data.parent_id) {
        parent = this.data.find((el) => el.id == data.id);
        parent.children.map((child) => {
          child.amount = data.amount;
        });
      }
    },
  },
};
</script>
<style>
.my-kh input,
.my-kh {
  width: 200px !important;
  /* height: 32px  !important;
  line-height: 32px  !important; */
  margin-right: 7px !important;
  padding-right: 7px !important;
  display: block !important;
}
.filter-tree .custom-tree-node {
  width: 96%;
  display: inline-table !important;
}

.filter-tree .el-tree-node__content .aaa {
  display: flex;
}
.filter-tree .el-tree-node__content {
  height: 40px;
}
</style>